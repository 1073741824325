import { useEffect } from 'react'
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc, collection, getDocs } from "firebase/firestore";
import Box from '@mui/material/Box'
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { getCookieConsentValue } from 'react-cookie-consent';

import Navbar from '../components/Navbar'
import Hero from '../components/Photographers/Hero'
// import LearnMore from '../components/Photographers/LearnMore'
import Form from '../components/Photographers/Form'
import Footer from '../components/Footer';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "photogig-subscribers.firebaseapp.com",
  projectId: "photogig-subscribers",
  storageBucket: "photogig-subscribers.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Photographers = () => {
  const location = useLocation();

  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === "true") {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);

  const joinWaitlist = async (photographer) => {
    await setDoc(doc(db, "waitlist", photographer.email), photographer)
    window.alert("Successfully subscribed! We'll let you know once we start accepting new photographers!")
  }

  return (
    <Box>
      <Navbar />
      <Hero />
      {/* <LearnMore /> */}
      <Form joinWaitlist={joinWaitlist} />
      <Footer />
    </Box>
  );
}

export default Photographers