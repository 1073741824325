import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import ReactGA from 'react-ga4';

import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from './Pages/Home.js';
import Photographers from './Pages/Photographers.js';
import Photographer from './Pages/Photographer.js';
import Preview from './Pages/Preview.js';
import Terms from './Pages/Terms.js';
import Privacy from './Pages/Privacy.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CookiesConsent from './components/CookiesConsent.js';

ReactGA.initialize(process.env.REACT_APP_GA_ID);

const theme = createTheme({
  palette: {
    primary: {
      main: '#5E74FE',
      light: '#5e9cfe',
      dark: '#705efe',
      contrastText: 'white',
    },
    secondary: {
      main: '#5E74FE',
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/photographers",
    element: <Photographers />,
  },
  {
    path: "/photographer/:username",
    element: <Photographer />,
  },
  {
    path: "/preview",
    element: <Preview />,
  },
  {
    path: "/terms-of-use",
    element: <Terms />,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CookiesConsent />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
