import React, { useEffect } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const CookiesConsent = () => {
  useEffect(() => {
    // Check if the user has already accepted cookies
    const consent = getCookieConsentValue();
    if (consent === "true") {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
    }
  }, []);

  return (
    <CookieConsent
      style={{
        backgroundColor: "#323232",
        color: "#fff",
        justifyContent: "center",
      }}
      buttonStyle={{
        color: "#fff",
        backgroundColor: "#5E74FE", // MUI primary color
        fontSize: "18px",
      }}
      onAccept={() => {
        // Initialize Google Analytics once consent is given
        ReactGA.initialize(process.env.REACT_APP_GA_ID);
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap" p={2}>
        <Typography variant="body1" sx={{ flexGrow: 1 }}>
          We use cookies to ensure you get the best experience on our website.{" "}
          <a href="/privacy-policy" style={{ color: '#90caf9' }}>Learn More</a>
        </Typography>
      </Box>
    </CookieConsent>
  );
}

export default CookiesConsent;
