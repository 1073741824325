import React, { useEffect } from 'react';
import Box from '@mui/material/Box'
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { getCookieConsentValue } from 'react-cookie-consent';

import Navbar from '../components/Navbar'
import Hero from '../components/Home/Hero'
import Summary from '../components/Home/Summary'
import Footer from '../components/Footer';
import Featured from '../components/Home/Featured';
import Map from '../components/Home/Map';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === "true") {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [location]);

  return (
    <Box>
      <Navbar />
      <Hero />
      <Map />
      <Featured />
      <Summary />
      <Footer />
    </Box>
  );
}

export default Home